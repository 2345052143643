export enum ManagementRoute {
  SETTINGS = 'settings',
  SURVEYS = 'surveys',
  USERS = 'users',
  ROLES = 'roles-management',
  NEW = 'new',
  EDIT = 'edit',
  RENEWAL_STRATEGY = 'renewal-strategy',
  KPI = 'kpi',
  PORTFOLIO = 'portfolio',
  Inspect = 'inspect',
  PMS_MAPPING = 'pms-mapping',
  VENDOR = 'vendor',
  SYSTEM = 'system',
  TURNOVER_CONFIGURATION = 'turnover-configuration',
  USER = 'user',
  DYNAMIC = 'dynamic',
  REPEATING = 'repeating',
  TEMPLATES = 'templates',
  SPACE_TEMPLATE = 'space-template',
  SERVICE = 'service',
}
